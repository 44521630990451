import React from "react"
import MainLayout from "../components/MainLayout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import PageHeading from "../components/PageHeadings"
import styled from "styled-components"

const VideoContainer = styled.div`
  padding: 2rem;

  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;

  iframe {
    width: 560px;
    height: 315px;
    margin-bottom: 2rem;
    @media (max-width: 600px) {
      width: 95%;
    }
  }

  @media (max-width: 1230px) {
    grid-template-columns: 100%;
  }
`

const VideoPage = () => {
  return (
    <>
      <SEO title="Media: Kodiak Scrubber Videos" />
      <Helmet>
        {/* <title>Kodiak Cleaning Equipment</title> */}
        <description></description>
      </Helmet>
      <MainLayout>
        <PageHeading pageHeading="Videos" />
        <VideoContainer>
          <iframe
            src="https://www.youtube.com/embed/K-Dkkq1Zg7c"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowFullScreen"
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/L63vcGNXs44"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowFullScreen"
          ></iframe>
          <iframe
            src="https://youtube.com/embed/ahExuXSVk34"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowFullScreen"
          ></iframe>
        </VideoContainer>
      </MainLayout>
    </>
  )
}

export default VideoPage
